//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  // layout: 'transfer',
  head() {
    return this.$metadata(this.story)
  },
  async validate({ $storyblok, params }) {
    // return await $storyblok.getStory(params.pathMatch || 'home')
    return true
  },
  async asyncData({ $storyblok, store, params }) {
    let story = await $storyblok.getStory('home')
    return {
      story
    }
  },
  beforeMount() {
    this.$store.commit('setShowNav', false)
    document.body.style.overflow = 'auto'
    // if(this.user) {
    //   this.$router.push('/')
    // } else {
    //   this.$router.push('/')
    //   // this.$router.push('/account/signin')
    // }
  },
  computed: {
    ...mapState(['user' ]),
  },
}
